/* Importa la fuente de Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&family=Rubik:wght@400;500&display=swap');
@import url('https://fontlibrary.org/en/font/chomsky');

/* Estilos generales */
body {
  font-family: 'Rubik', sans-serif;
  background: linear-gradient(135deg, #D9ED92, #184E77);
  color: #333;
  margin: 0;
  padding: 0;
}

/* Estilos para las secciones */
section {
  margin: 20px;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  position: relative;
}

/* Estilos para los botones */
button {
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: #34A0A4;
  color: white;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #168AAD;
}

/* Estilos para la imagen de perfil */
.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto 20px;
  border: 5px solid #52B69A;
}

/* Estilos para etiquetas */
.tag {
  display: inline-block;
  background-color: #1E6091;
  color: white;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  font-size: 0.8em;
}

/* Contenido adicional para las tarjetas */
.card-content {
  padding: 15px;
}

.card-title {
  font-size: 1.5em;
  color: #1A759F;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1em;
  margin-bottom: 10px;
}

.card-footer {
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid #99D98C;
}

.tarjeta-proyecto {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 30%;
  margin: 1%;
}

.imagen-proyecto {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.imagen-post {
  max-width: 30%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.post {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}

.boton-like {
  position: absolute;
  bottom: 10px; /* Ajusta la distancia desde el fondo */
  right: 10px;  /* Ajusta la distancia desde la derecha */
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 2em;
}

.post button:hover {
  opacity: 0.7;
}

#daily-jobseeker h1 {
  font-family: 'Chomsky', sans-serif;
}

.filtro-etiquetas-container {
  overflow-x: auto;
  white-space: nowrap;
}

.filtro-etiquetas button {
  display: inline-block;
  margin-right: 5px;
}

.categoria {
  margin-bottom: 10px;
}

.etiquetas {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.etiquetas button {
  margin-right: 5px;
}

.categoria-full-stack,
.categoria-diseno,
.categoria-videojuegos,
.categoria-rpa {
  border-radius: 20px;
}

.boton-etiqueta {
  background-color: #e7e7e7;
  border: none;
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: default;
  border-radius: 4px;
}

/* Media queries para diseño responsivo */
@media (max-width: 768px) {
  .tarjeta-proyecto,
  .post {
    width: 45%;
    margin: 2.5%;
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .tarjeta-proyecto,
  .post {
    width: 90%;
    margin: 5% auto;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  /* Ajusta el tamaño de los botones para dispositivos móviles */
  button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
.categoria-full-stack {
  background-color: #9d0208; /* Verde */
}

.categoria-rpa {
  background-color: #01497c; /* Azul */
}

.categoria-diseno {
  background-color: #3c096c; /* Rojo */
}

.categoria-videojuegos {
  background-color: #ff9f1c; /* Amarillo */
}

.select-style {
  padding: 10px;
  margin: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  appearance: none; /* This is to remove the default browser styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: 'Rubik', sans-serif; /* Use the same font for consistency */
  font-size: 16px;
  cursor: pointer;
}

/* Style for the arrow icon */
.select-style:after {
  content: '▼';
  font-size: 12px;
  right: 10px;
  top: calc(50% - 6px);
  position: absolute;
  pointer-events: none;
}

/* Style for hover effect */
.select-style:hover {
  border-color: #b3b3b3;
}

/* Style for focus effect */
.select-style:focus {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

.iconos-redes{
  width: 4%;  
  height: 50%;
  margin-right: 2%;
  
}
@media screen and (max-width: 768px) {
  .iconos-redes {
    width: 10%;  /* Aumenta el porcentaje para un tamaño más grande */
    height: auto; /* Mantiene la proporción del icono */
  }
}

.tarjeta-proyecto a {
  text-decoration: none; /* Elimina el subrayado */
  color: inherit; /* Hereda el color del elemento padre */
}

.tarjeta-proyecto a:hover {
  text-decoration: underline; /* Opcional: añade subrayado al pasar el ratón */
}
.navegacion-proyectos,
.navegacion-posts {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.navegacion-proyectos,
.navegacion-posts {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.boton-anterior,
.boton-siguiente {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.boton-anterior {
  left: 0px; /* Ajusta este valor para mover la flecha hacia adentro */
}

.boton-siguiente {
  right: -10px; /* Ajusta este valor para mover la flecha hacia adentro */
}

